import React from 'react';
import {
  Flex,
  Box,
  Body,
  Text,
  H5,
  FAIcon,
  AccordionWidget,
} from '@fivehealth/botero';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { uniqWith, isEqual } from 'lodash';
import { useAppData } from '../context/AppDataContext';
import { Cost } from './Results.types';

export default function InputSummary() {
  const { results, selectedRegimens } = useAppData();
  const combinedDoseMethods = new Set(
    selectedRegimens
      .map(({ regimen: selectedRegimen }) => selectedRegimen.doseMethods)
      .flat()
  );

  const getSubsidyStatus = (result: Cost) => {
    let subsidyResult = '';
    if (result.userInputs.subsidyStatus === 'subsidised') {
      subsidyResult += 'Subsidised';
    } else if (result.userInputs.subsidyStatus === 'private') {
      subsidyResult += 'Private';
    } else {
      subsidyResult += 'Non-Resident';
    }

    if (
      result.userInputs.subsidyStatus !== 'non-resident' &&
      result.userInputs.residency === 'sc'
    ) {
      subsidyResult += ', Singapore Citizen';
    } else if (
      result.userInputs.subsidyStatus !== 'non-resident' &&
      result.userInputs.residency === 'pr'
    ) {
      subsidyResult += ', Permanent Resident';
    }

    if (
      result.userInputs.residency === 'sc' &&
      result.userInputs.generation === 'mg'
    ) {
      subsidyResult += ', Merdeka Generation';
    } else if (
      result.userInputs.residency === 'sc' &&
      result.userInputs.generation === 'pg'
    ) {
      subsidyResult += ', Pioneer Generation';
    } else if (result.userInputs.residency === 'sc') {
      subsidyResult += ', NIL';
    }

    if (
      result.userInputs.subsidyStatus === 'subsidised' &&
      result.userInputs.phci <= 1200
    ) {
      subsidyResult += ', $0 < PCHI ≤ $1500 [75% SDL & 75% MAF Subsidy]';
    } else if (
      result.userInputs.subsidyStatus === 'subsidised' &&
      result.userInputs.phci <= 2000
    ) {
      subsidyResult += ', $1500 < PCHI ≤ $2300 [75% SDL & 75% MAF Subsidy]';
    } else if (
      result.userInputs.subsidyStatus === 'subsidised' &&
      result.userInputs.phci <= 3300
    ) {
      subsidyResult += ', $2300 < PCHI ≤ $3600 [50 % SDL & 50% MAF Subsidy]';
    } else if (
      result.userInputs.subsidyStatus === 'subsidised' &&
      result.userInputs.phci <= 6500
    ) {
      subsidyResult += ', $3600 < PCHI ≤ $7000 [50 % SDL & 40 % MAF Subsidy]';
    } else if (result.userInputs.subsidyStatus === 'subsidised') {
      subsidyResult += ', PCHI > $7000 [50 % SDL Subsidy]';
    }

    return subsidyResult;
  };

  function getAdditionalDetails(_results: Cost[]) {
    let brandStatus = '';
    if (_results[0].userInputs.brandStatus === 'branded') {
      brandStatus += 'Branded';
    } else if (_results[0].userInputs.brandStatus === 'generic or biosimilar') {
      brandStatus += 'Generic or Biosimilar';
    }
    const mafDrugs = uniqWith(
      _results
        .map((result) =>
          result.mafIndications.map((mafIndication) => mafIndication.drug)
        )
        .flat(1),
      isEqual
    );
    return (
      <>
        {brandStatus.length > 0 && <Text>{brandStatus}</Text>}
        {mafDrugs.map((drug) => (
          <Text key={drug}>{`${drug}: MAF-Approved Indication?: ${
            _results
              .map((result) => result.userInputs.mafDrugs)
              .flat(1)
              .includes(drug)
              ? 'Yes'
              : 'No'
          }`}</Text>
        ))}
      </>
    );
  }

  return (
    <AccordionWidget
      data={[
        {
          id: 'test-1', // NOTE: Use as a unique key for the accordion widget
          name: 'test',
          isOpen: false,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex bg="white" justifyContent="space-between">
          <H5 fontWeight={500}>Input summary</H5>
          <Box>
            <Body>
              <FAIcon
                icon={faChevronDown}
                style={{
                  height: 16,
                  transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                }}
              />
            </Body>
          </Box>
        </Flex>
      )}
      renderBody={() => (
        <>
          <Flex flexDirection="row" pt={2}>
            <H5 color="#697481" fontWeight={500} flex={1}>
              Patient Details
            </H5>
            {!results.length && (
              <H5 color="#111824" fontStyle="italic" fontWeight={400} flex={4}>
                Please input details
              </H5>
            )}
            {results.length > 0 &&
              (combinedDoseMethods.size === 1 &&
              combinedDoseMethods.has('fixed') ? (
                <H5
                  color="#111824"
                  fontStyle="italic"
                  fontWeight={400}
                  flex={4}
                >
                  Fixed dosage regimen
                </H5>
              ) : (
                <H5 color="#111824" fontWeight={400} flex={4}>
                  {`${
                    results[0].userInputs.height
                      ? `${results[0].userInputs.height}cm, `
                      : ''
                  }${
                    results[0].userInputs.weight
                      ? `${results[0].userInputs.weight}kg, `
                      : ''
                  }${
                    results[0].userInputs.bsa
                      ? `${results[0].userInputs.bsa.toFixed(2)}m²`
                      : ''
                  }`}
                </H5>
              ))}
          </Flex>
          <Flex flexDirection="row" pt={2}>
            <H5 color="#697481" fontWeight={500} flex={1}>
              Subsidy Details
            </H5>
            {!results.length && (
              <H5 color="#111824" fontStyle="italic" fontWeight={400} flex={4}>
                Please input details
              </H5>
            )}
            {results.length > 0 && (
              <H5 color="#111824" fontWeight={400} flex={4}>
                {getSubsidyStatus(results[0])}
              </H5>
            )}
          </Flex>
          <Flex flexDirection="row" pt={2}>
            <H5 color="#697481" fontWeight={500} flex={1}>
              Additional Details
            </H5>
            {!results.length && (
              <H5 color="#111824" fontStyle="italic" fontWeight={400} flex={4}>
                Please input details
              </H5>
            )}
            {results.length > 0 && (
              <H5 color="#111824" fontWeight={400} flex={4}>
                {getAdditionalDetails(results)}
              </H5>
            )}
          </Flex>
        </>
      )}
      containerProps={{
        padding: '16px',
        border: '1px solid #D5D7DE',
        borderRadius: '8px',
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{ backgroundColor: 'white', minHeight: 50 }}
      alternateBgColor={false}
    />
  );
}
